import React, { useEffect, useState } from "react";
import DataVisualization from "./DataVisualization";
import * as DataFetcher from "./DataFetcher";
import {
    Redirect,
    Route,
    Switch,
    useHistory
} from "react-router-dom";

/* global gapi */

let GoogleAuth;

export default function App() {

    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [hasClickedStart, setHasClickedStart] = useState(false);

    const history = useHistory();

    useEffect(() => {
        let intervalId = null;
        window.gapi.load('client', () => {
            window.gapi.client.init({
                'apiKey': 'AIzaSyAZDpYpHPRFUaK6-hVWEOA8SHtK2tjS1SM',
                // Your API key will be automatically added to the Discovery Document URLs.
                'discoveryDocs': ['https://youtube.googleapis.com/$discovery/rest'],
                // clientId and scope are optional if auth is not required.
                'clientId': '706765614047-6lbljcpi292nspetgcftsmoasplvilk4.apps.googleusercontent.com',
                'scope': 'https://www.googleapis.com/auth/youtube.readonly',
            }).then(function () {
                GoogleAuth = gapi.auth2.getAuthInstance();
                //console.log(GoogleAuth.currentUser.get().getBasicProfile());
                //GoogleAuth.isSignedIn.listen(isSignedIn => setIsAuthorized(isSignedIn));

                if (GoogleAuth.isSignedIn.get()) {
                    setIsAuthorized(true);
                } else {
                    intervalId = setInterval(() => {
                        console.log("Checking authorization status");
                        if (GoogleAuth.isSignedIn.get()) {
                            console.log("Authorization status from GoogleAuth changed to true");
                            setIsAuthorized(true);
                            clearInterval(intervalId);
                        }
                    }, 1500);
                }
            });
        });

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        }
    }, []);

    useEffect(() => {
        if (isAuthorized && hasClickedStart) {
            console.log("Authorization status state changed to true");
            fetchData();
        }
    }, [isAuthorized]);

    function startClick() {
        setHasClickedStart(true);
        setIsLoading(true);

        if (isAuthorized) {
            console.log("Already authorized");
            fetchData();
        } else {
            console.log("Poping authorization window");
            GoogleAuth.signIn();
        }
    }

    function fetchData() {
        console.log("Fetching data");
        DataFetcher.loadData(gapi).then(data => {
            setIsLoading(false);
            setData(data);

            history.push("/data");
        });
    }

    function clearData(){
        DataFetcher.clearData();
        setData(null);
        setHasClickedStart(false);
        console.log("Clearing data");
    }

    return (
        <Switch>
            <Route exact path="/">
                <div className="container">
                    <div className="text-center mt-4">
                        <h1 className="display-4">Youtube History Visualization</h1>
                        <br />
                        {!hasClickedStart && <button className="btn btn-primary btn-lg" onClick={() => startClick()}>Start</button>}
                        {isLoading && (
                            <div className="spinner-border text-danger" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </div>
                </div>
            </Route>
            <Route path="/data/:date?">
                {data ? <DataVisualization data={data} clearData={clearData} /> : <Redirect to={"/"} />}
            </Route>
        </Switch>
    );
}