import React from 'react';
import { useParams } from 'react-router-dom';
import VideoList from "./VideoList";

export default function WideosWithChannel(props) {
    let { channelName } = useParams();

    return (
        <>
            <h2 className="text-center mb-3">Videos from the channel: {channelName}</h2>
            {props.data && <VideoList videos={props.data.filter(v => v.channelTitle === channelName)} />}
        </>
    )
}