const localStorageKey = "SavedData";
const dataStorageVersion = 1;

export function loadData(gapi) {
    let storedData = localStorage.getItem(localStorageKey);

    if (storedData) {
        storedData = JSON.parse(storedData);
        if (storedData.storageVersion === dataStorageVersion) {
            console.log("Loading data from local storage");
            return new Promise((resolve) => resolve(storedData.data));
        } else {
            console.log("Stored data uses an old version, deleting");
            localStorage.removeItem(localStorageKey);
        }
    }

    return gapi.client.youtube.channels.list({
        part: 'snippet,contentDetails,statistics',
        mine: true
    }).then(response => {
        var channels = response.result.items;
        if (response.result.pageInfo.totalResults === 0) {
            console.error('No channel found.');
            return [];
        } else {
            var likeHistoryId = channels[0].contentDetails.relatedPlaylists.likes;

            return loadHistory(gapi, likeHistoryId).then(data => {
                localStorage.setItem(localStorageKey, JSON.stringify({ data: data, storageVersion: dataStorageVersion }));
                return data;
            });
        }
    }, err => {
        console.error(err);
    });
}

export function clearData() {
    localStorage.removeItem(localStorageKey);
}

async function loadHistory(gapi, playlistId) {
    var data = [];

    var videos = await getPlaylistVideos(gapi, playlistId);

    for (const like of videos) {
        console.log(like);
        var videoInfo = await getVideoInfo(gapi, like.snippet.resourceId.videoId);

        data.push({
            id: like.snippet.resourceId.videoId,
            likeDate: like.snippet.publishedAt,
            title: like.snippet.title,
            thumbnailUrl: videoInfo.thumbnailUrl,
            channelId: videoInfo.channelId,
            channelTitle: videoInfo.channelTitle,
            tags: videoInfo.tags
        })
    }

    return data;
}

function getPlaylistVideos(gapi, playlistId) {
    return gapi.client.youtube.playlistItems.list({
        part: 'snippet',
        playlistId: playlistId,
        maxResults: 50
    }).then(response => response.result.items);
}

function getVideoInfo(gapi, videoId) {
    return gapi.client.youtube.videos.list({
        part: 'snippet',
        id: videoId
    }).then(response => {
        let video = response.result.items[0].snippet;
        let thumbnailUrl = null;

        if (video.thumbnails) {
            let thumbnail = video.thumbnails.standard || video.thumbnails.high || video.thumbnails.medium || video.thumbnails.default;
            if (thumbnail) {
                thumbnailUrl = thumbnail.url;
            }
        }

        return {
            tags: video.tags || [],
            channelId: video.channelId,
            channelTitle: video.channelTitle,
            thumbnailUrl: thumbnailUrl
        };
    });
}