import React from 'react';
import './App.css';
import DateSlider from './DateSlider';
import { useHistory, Redirect, Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import VideosWithTag from "./VideosWithTag";
import VideosWithChannel from "./VideosWithChannel";
import TagsTreeMap from "./TagsTreeMap";
import ChannelsTreeMap from "./ChannelsTreeMap";
import * as classNames from 'classnames';

export default function DataVisualization(props) {
  const { date } = useParams();

  let history = useHistory();

  var completeData = [];

  props.data.forEach(entry => {
    const year = new Date(entry.likeDate).getUTCFullYear();
    const month = new Date(entry.likeDate).getUTCMonth();
    const yearMonth = year + "-" + month;

    if (!completeData[yearMonth]) {
      completeData[yearMonth] = [];
    }

    completeData[yearMonth].push(entry);
  });

  let yearMonths = [];
  for (let yearMonth in completeData) {
    yearMonths.push(yearMonth);
  }
  yearMonths.reverse();

  const isTagPage = window.location.pathname.includes("/tags");
  const isChannelPage = window.location.pathname.includes("/channels");

  function onDateChange(value) {
    history.push("/data/" + value + (isTagPage ? "/tags" : "/channels"));
  }

  let { path, url } = useRouteMatch();

  if (!date) {
    return <Redirect to={"/data/" + yearMonths[yearMonths.length - 1]} />;
  }

  function clearData(ev) {
    props.clearData();
    ev.preventDefault();
  }

  return (
    <div className="container">
      {props.data.length === 0 ? (
        <>No data to display</>
      ) : (
          <>
            <h1 className="text-center">Youtube History Visualization</h1>
            <div className="text-center mb-3">
              <a href="#" onClick={clearData}>Clear Data</a>
            </div>
            <DateSlider dates={yearMonths} onChange={onDateChange} />

            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link className={classNames("nav-link", { "active": isTagPage })} to={url + "/tags"}>Tags</Link>
              </li>
              <li className="nav-item">
                <Link className={classNames("nav-link", { "active": isChannelPage })} to={url + "/channels"}>Channels</Link>
              </li>
            </ul>

            <Switch>
              <Route exact path={path}>
                <Redirect to={url + "/tags"} />
              </Route>
              <Route exact path={path + "/tags"}>
                {date && <TagsTreeMap data={completeData[date]} onTagClick={tag => history.push(url + "/tags/" + tag)} />}
              </Route>
              <Route exact path={path + "/tags/:tagName"}>
                <VideosWithTag data={completeData[date]} />
              </Route>
              <Route exact path={path + "/channels"}>
                {date && <ChannelsTreeMap data={completeData[date]} onChannelClick={channel => history.push(url + "/channels/" + channel)} />}
              </Route>
              <Route exact path={path + "/channels/:channelName"}>
                <VideosWithChannel data={completeData[date]} />
              </Route>
            </Switch>
          </>
        )
      }
    </div >
  );
}
