import React from 'react';

export default function VideoList(props) {
    return (
        <div className="videoList">
            {
                props.videos.map(video => {
                    return (
                        <div key={video.id} className="media mb-2">
                            <a href={"https://www.youtube.com/watch?v=" + video.id} rel="noreferrer noopener" target="_blank">
                                <img width={250} src={video.thumbnailUrl} alt={video.title} className="mr-3" />
                            </a>
                            <div className="media-body">
                                <a href={"https://www.youtube.com/watch?v=" + video.id} rel="noreferrer noopener" target="_blank">
                                    <h5 className="mt-0">{video.title}</h5>
                                </a>

                                <a href={"https://www.youtube.com/channel/" + video.channelId} rel="noreferrer noopener" target="_blank">
                                    {video.channelTitle}
                                </a>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}
