import React from 'react';
import TreeMap from "./TreeMap";

var seedrandom = require('seedrandom');

export default function ChannelsTreeMap(props) {
    let channels = [];

    for (let v of props.data) {
        if (channels.some(x => x.title === v.channelTitle)) {
            channels.find(x => x.title === v.channelTitle).count++;
        } else {
            channels.push({ title: v.channelTitle, count: 1 });
        }
    }

    channels = channels.sort((a, b) => b.count - a.count);
    channels = channels.map(d => {
        var myrng = new seedrandom(d.title);
        return {
            name: d.title,
            size: d.count,
            color: myrng() * 0.8,
        }
    }).slice(0, 30);

    return channels && <TreeMap data={channels} onNodeClick={name => props.onChannelClick(name)} />;
}