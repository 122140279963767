import React, { useEffect, useState } from 'react';

import { Treemap } from 'react-vis';

export default function TreeMap(props) {
  const [treeProps, setTreeProps] = useState(null);

  const { data, onNodeClick } = props;

  useEffect(() => {
    const treeProps = {
      animation: {
        damping: 9,
        stiffness: 300
      },
      data: {
        title: '',
        color: 1,
        children: data
      },
      onLeafClick: ev => {
        if (ev.depth > 0) {
          onNodeClick(ev.data.name);
        }
      },
      mode: 'circlePack',
      getLabel: x => x.name,
      height: 800,
      width: 800
    };

    setTreeProps(treeProps);
  }, [data, onNodeClick]);

  return (
    <div className="treeMapContainer">
      {treeProps && <Treemap {...treeProps} />}
    </div>
  );
}